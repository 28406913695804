<template>
  <div class="app">
    <slot />
  </div>
</template>

<style lang="postcss" scoped>
.app {
  min-height: 100vh;
  overflow-x: clip;
  max-width: 100%;
}
</style>
